import React, { useEffect, useState, useRef } from 'react';
import { GraphQLClient, gql } from "graphql-request";
import { TableauEventType } from "https://eu-west-1a.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js";

const generateTableauToken = gql`
    mutation GenerateTableauToken {
      generateTableauToken: desanaBusinessAnalyticsV1AnalyticsServiceGenerateTableauToken(
        input: {}
      ) {
        response {
          token
        }
      }
    }
  `;

const client = new GraphQLClient(process.env.REACT_APP_PUBLIC_API_ENDPOINT, {
  credentials: "include",
  mode: "cors",
});

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

//This function calculates the date frequency of view
const calculateDateTypeParameter = (startDate, endDate) => {
  const numberOfDays = endDate.diff(startDate, "days");
  let dateTypeParameter = "Weekly"; // Default value

  if (numberOfDays > 0 && numberOfDays <= 60) {
    dateTypeParameter = "Daily";
  } else if (numberOfDays <= 180) {
    dateTypeParameter = "Weekly";
  } else if (numberOfDays <= 365) {
    dateTypeParameter = "Monthly";
  } else if (numberOfDays <= 2 * 365) {
    dateTypeParameter = "Quarterly";
  } else if (numberOfDays > 2 * 365) {
    dateTypeParameter = "Yearly";
  }

  return dateTypeParameter;
}

const TableauEmbed = ({ selectedLink, height, startDate, endDate, className }) => {
  const [viz, setViz] = useState();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const vizRef = useRef(null);

  const loadViz = () => {
    setViz(
      <tableau-viz
        key={selectedLink}
        ref={vizRef}
        id="tableauViz"
        src={selectedLink}
        device="desktop"
        hide-tabs={true}
        token={token}
        toolbar='hidden'
        class="flex w-screen max-w-screen-2xl"
        style={{ height: height }}
      >
        <viz-parameter
          name="Start Date"
          value={startDate.format("YYYY-MM-DD")}
        />
        <viz-parameter
          name="End Date"
          value={endDate.format("YYYY-MM-DD")}
        />
        <viz-parameter
          name="Analyse - Date Type"
          value={"Weekly"}
        />
      </tableau-viz>
    );
  };

  useEffect(function setupTableauAuth() {
    const fetchAndSetToken = () => {
      setLoading(true);
      client.request(generateTableauToken)
        .then((response) => {
          setToken(response.generateTableauToken.response.token);
        }).catch((error) => {
          setError(error);
          // If the request fails, redirect to admin dashboard
          window.location.replace(process.env.REACT_APP_ADMIN_DASHBOARD_URL);
        }).finally(() => {
          setLoading(false);
        });
    }

    // On mount
    fetchAndSetToken();

    // Every 9 minutes
    const interval = setInterval(() => {
      fetchAndSetToken();
    }, 1000 * 60 * 9);

    return () => clearInterval(interval);
  }, []);

  useEffect(function initialLoad() {
    if (token) {
      loadViz();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, selectedLink, height])

  useEffect(function handleParameterChanges() {
    vizRef.current?.workbook?.changeParameterValueAsync?.("Start Date", startDate.format("YYYY-MM-DD"));
    vizRef.current?.workbook?.changeParameterValueAsync?.("End Date", endDate.format("YYYY-MM-DD"));
    vizRef.current?.workbook?.changeParameterValueAsync?.("Analyse - Date Type", calculateDateTypeParameter(startDate, endDate));
  }, [startDate, endDate]);

  useEffect(function setupEventListeners() {
    if (!vizRef.current) return;

    vizRef.current.addEventListener(
      TableauEventType.TabSwitched,
      handleTabSwitch
    );
    vizRef.current.addEventListener(
      TableauEventType.FilterChanged,
      scrollToTop
    );
    vizRef.current.addEventListener(
      TableauEventType.ParameterChanged,
      scrollToTop
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vizRef.current])


  const handleTabSwitch = (e) => {
    const activeSheet = vizRef.current.workbook.activeSheet;

    if (activeSheet.name.includes("View More")) {
      // Store the current scroll position in localStorage if it's not zero
      if (window.scrollY !== 0) {
        localStorage.setItem("scrollPos", window.scrollY);
      }

      scrollToTop();
    } else {
      // Apply the stored scroll position
      var scrollPos = localStorage.getItem("scrollPos");
      if (scrollPos) {
        window.scrollTo(0, scrollPos);
      }
    }
  };

  if (loading) return "Loading..."
  if (error) return "Error! " + JSON.stringify(error);

  // Full width and height
  return (
    <div className={className}>
      {viz}
    </div>
  )
};

export default TableauEmbed;