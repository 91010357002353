import { useEffect, useRef } from 'react';
import moment from 'moment';
import 'daterangepicker/daterangepicker.js';
import 'daterangepicker/daterangepicker.css';
import $ from 'jquery';

const DateRangePicker = ({ setNumberOfDays, setDateRange, initialStartDate, initialEndDate }) => {
  const dateRangePickerRef = useRef(null);

  useEffect(() => {
    // If the picker doesn't exist yet
    if (!dateRangePickerRef.current) {
      const startDate = initialStartDate;
      const endDate = initialEndDate;
      const days = endDate.diff(startDate, 'days') + 1;

      setNumberOfDays(days);

      const dateRangeText = startDate.format('MMMM D, YYYY') + ' - ' + endDate.format('MMMM D, YYYY');
      $('#reportrange span').html(dateRangeText);

      dateRangePickerRef.current = $('#reportrange').daterangepicker(
        {
          startDate: startDate,
          endDate: endDate,
          ranges: {
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 28 Days': [moment().subtract(27, 'days'), moment()],
            'Last 90 Days': [moment().subtract(89, 'days'), moment()],
            'Last 6 Months': [moment().subtract(6, 'month'), moment()],
            'Last Year': [moment().subtract(1, 'year'), moment()],
          },
        },
        (start, end) => {
          const days = end.diff(start, 'days') + 1;
          setNumberOfDays(days);
          setDateRange(start, end);

          const dateRangeText = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');
          $('#reportrange span').html(dateRangeText);
        },
      );

      return;
    }

    // If the picker already exists
    $('#reportrange').data('daterangepicker').setStartDate(initialStartDate);
    $('#reportrange').data('daterangepicker').setEndDate(initialEndDate);
    const dateRangeText = initialStartDate.format('MMMM D, YYYY') + ' - ' + initialEndDate.format('MMMM D, YYYY');
    $('#reportrange span').html(dateRangeText);

    const days = initialEndDate.diff(initialStartDate, 'days') + 1;
    setNumberOfDays(days);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStartDate, initialEndDate]);

  return null;
};

export default DateRangePicker;