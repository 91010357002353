import { PAGES } from "./App";

export const PageLinks = ({ selectedPage, handleLinkClick, startDate, endDate }) =>
(<div className='flex mt-0 justify-start space-x-4 ml-2'>
  {Object.keys(PAGES).map((page) => {
    const { name, slug } = PAGES[page];
    const isSelected = selectedPage?.slug === slug;
    const className = `hover:text-[#3b37f6] decoration-2 decoration-[#3b37f6] underline-offset-8 ${isSelected ? 'text-[#3b37f6] underline' : 'text-gray-400 no-underline'}`;


    const url = new URL(window.location.toString());
    url.searchParams.set("start", startDate.format("YYYY-MM-DD"));
    url.searchParams.set("end", endDate.format("YYYY-MM-DD"));
    url.pathname = slug;

    return (
      <a
        key={page}
        id={page}
        href={url}
        onClick={(e) => {
          e.preventDefault();
          handleLinkClick(page)
        }}
        className={className}
      >
        {name}
      </a>
    );
  })}
</div>)
