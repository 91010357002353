import '@fortawesome/fontawesome-free/css/all.css';
import moment from "moment";
import 'moment/min/moment.min.js';
import React, { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import DateRangePicker from './DateRangePicker.js';
import { PageLinks } from './PageLinks.jsx';
import TableauEmbed from './TableauEmbed.jsx';

export const PAGES = {
  "network-overview": {
    url: process.env.REACT_APP_URL_1,
    name: 'Network overview',
    slug: 'network-overview',
    height: '1900px'
  },
  "network-details": {
    url: process.env.REACT_APP_URL_2,
    name: 'Network details',
    slug: 'network-details',
    height: '2600px'
  },
  "hq-usage": {
    url: process.env.REACT_APP_URL_3,
    name: 'HQ Usage',
    slug: 'hq-usage',
    height: '2200px'
  }
};

const App = () => {
  const url = new URL(window.location.toString())
  const start = url.searchParams.get("start");
  const end = url.searchParams.get("end");
  const startMoment = moment(start, "YYYY-MM-DD");
  const endMoment = moment(end, "YYYY-MM-DD");

  const [startDate, setStartDate] = useState((startMoment.isValid() ? startMoment : moment().subtract(27, "days")).startOf("day"));
  const [endDate, setEndDate] = useState((endMoment.isValid() ? endMoment : moment()).endOf("day"));
  const [numberOfDays, setNumberOfDays] = useState(endDate.diff(startDate));
  const [selectedPage, setSelectedPage] = useState();

  const handleLinkClick = (link) => {
    // Remove local storage scroll position
    localStorage.clear("scrollPos");

    // Set the selected page
    setSelectedPage(PAGES[link]);

    const url = new URL(window.location.toString());
    url.searchParams.set("start", startDate.format("YYYY-MM-DD"));
    url.searchParams.set("end", endDate.format("YYYY-MM-DD"));
    url.pathname = PAGES[link].slug;

    window.history.pushState({}, "", url);
  };

  useEffect(() => {
    const pathname = window.location.pathname.substring(1);
    if (!pathname) {
      window.history.replaceState({}, "", PAGES["network-overview"].slug);
      setSelectedPage(PAGES["network-overview"]);
    } else {
      const foundPage = PAGES[pathname];
      if (foundPage) {
        setSelectedPage(foundPage);
      } else {
        setSelectedPage(PAGES["network-overview"]);

        const url = new URL(window.location.toString());
        url.searchParams.set("start", startDate.format("YYYY-MM-DD"));
        url.searchParams.set("end", endDate.format("YYYY-MM-DD"));
        url.pathname = PAGES["network-overview"].slug;

        window.history.replaceState({}, "", url);
      }
    }

    window.addEventListener("popstate", (e) => {
      const url = new URL(e.target.location.toString());
      const start = url.searchParams.get("start");
      const end = url.searchParams.get("end");
      setStartDate(moment(start, "YYYY-MM-DD").startOf("day"));
      setEndDate(moment(end, "YYYY-MM-DD").endOf("day"));
      setSelectedPage(PAGES[url.pathname.substring(1)]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigate = () => {
    window.location.href = 'https://admin.desana.io';
  };

  return (
    <div className="flex flex-col h-screen">
      <nav className="sticky top-0 z-10">
        <div style={{
          position: 'fixed', top: 0, width: '100%', background: '#fff', zIndex: 1000, padding: '10px', display: 'flex',
          alignItems: 'center', justifyContent: 'space-between'
        }}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0 auto' }}>
            {/* Logo and Links on the left */}
            <div className='flex items-center'>
              <img src="/LogoPrimary128.png" alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
              <Button
                variant="outline-secondary"
                style={{ marginLeft: '10px', marginRight: '10px' }}
                onClick={handleNavigate}
              >
                <i className="fa fa-arrow-left" aria-hidden="true" style={{ color: '#000000', marginRight: '5px' }}></i>
                Back to main dashboard
              </Button>
              <PageLinks selectedPage={selectedPage} handleLinkClick={handleLinkClick} startDate={startDate} endDate={endDate} />
            </div>

            {/* Date range picker and badge on the far right */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* Include DateRangePicker component here */}
              <DateRangePicker
                initialStartDate={startDate.clone()}
                initialEndDate={endDate.clone()}
                setNumberOfDays={setNumberOfDays}
                setDateRange={(start, end) => {
                  setStartDate(start);
                  setEndDate(end);

                  const url = new URL(window.location.toString());
                  url.searchParams.set("start", start.format("YYYY-MM-DD"));
                  url.searchParams.set("end", end.format("YYYY-MM-DD"));
                  window.history.pushState({}, "", url);
                }}
              />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div id="reportrange" style={{ background: '#fff', cursor: 'pointer', padding: '5px 10px', border: '1px solid #ccc' }}>
                  <i className="fa fa-calendar"></i>&nbsp;
                  <span></span> <i className="fa fa-caret-down"></i>
                </div>
                <div style={{ marginLeft: '10px' }}>
                  <Badge bg="light" text="dark">
                    {numberOfDays} {numberOfDays === 1 ? 'day' : 'days'}
                  </Badge>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* <p style={{ marginTop: "100px" }}>
        Start:
        {startDate.toISOString()}&nbsp;
      </p>
      <p style={{ marginTop: "100px" }}>
        End:
        {endDate.toISOString()}
      </p> */}
      {selectedPage && <TableauEmbed className='flex flex-1 mt-20 justify-center px-2' selectedLink={selectedPage.url} height={selectedPage.height} startDate={startDate} endDate={endDate} handleLinkClick={handleLinkClick} />}
    </div>
  );
};

export default App;